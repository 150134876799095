import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import Header from './Header';
import './AboutUs.css';
import LogoTekt from './images/footer/tekt_ai.png';
import InstagramIcon from './images/footer/insta-icon.png';
import TwitterIcon from './images/footer/twitter-icon.png';
import LinkdinIcon from './images/footer/linkdin-icon.png';
import TiktokIcon from './images/footer/tiktok-icon.png';


function AboutUs() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => setIsAuthenticated(!!user)
        );
        return () => unregisterAuthObserver();
    }, []);

    return (
        <div className="about-us-container">
            <Header isAuthenticated={isAuthenticated} />  {/* Pass isAuthenticated prop to Header */}
            <section className="about-us-content">
                <h1>Our Policy 📄</h1>
                <h3>Last updated: [October 21st 2023]</h3>
                <h5>This Privacy Policy describes how Tekt collects, uses, and discloses information when you use our Ai architectural app.
 </h5>

                <h3>Information Collection:</h3>
                <h2>We collect the following information when you use our app:
Generated images and styles.
Generated renders.
Usage data, like how you interact with our app.</h2>

                <h3>Purpose of Collection:</h3>
                <h2>Provide and improve our services.
Answer your architectural queries.
Generate and customize images and renders as per your requirements.</h2>

                <h3>Third-Party Services:</h3>
                <h2>We do not share your information with third-party services without your explicit consent.</h2>

                <h3>Security:</h3>
                <h2>We employ industry-standard security measures to protect your information.</h2>

                <h3>Cancellation of Subscription:</h3>
                <h2>Cancellation of subscription can only be done by contacting our support team via email at info@tekt.ai .</h2>
                <h3>Changes to Privacy Policy:</h3>
                <h2>We will notify you of any changes to our Privacy Policy through in-app notifications or email.</h2>
                <h3>Contact Us:</h3>
                <h2>For any concerns or questions regarding this Privacy Policy, please contact us at info@tekt.ai</h2>

                <h2>Tekt.ai. Architekt Your Own Future.</h2>
            </section>
            
            <footer className="footer-aboutus">
                <div className="logo-footer">
                    <img src={LogoTekt} className="logo-footer" alt="Tekt Ai Logo" />
                </div>
                <div className="rights">
                    <p className="footer-sentence">Copyright © 2024 Tekt Ai. All Rights Reserved.</p>
                </div>
                <div className="socials">
                    <a href="https://instagram.com/tekt.ai" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={InstagramIcon} alt="Instagram" width="20" height="20" /></a>
                    <a href="https://twitter.com/tekt_ai" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={TwitterIcon} alt="Twitter" width="20" height="20" /></a>
                    <a href="https://www.linkedin.com/company/tekt-ai/" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={LinkdinIcon} alt="Linkdin" width="20" height="20" /></a>
                    <a href="https://www.tiktok.com/company/tekt.ai/" className="social-icon" target="_blank" rel="noopener noreferrer"><img src={TiktokIcon} alt="Tiktok" width="20" height="20" /></a>
                </div>
            </footer>
        </div>
    );
}

export default AboutUs;
